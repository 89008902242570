<template>
  <div class="warning">
    <el-form label-width="100px" :model="formData" :rules="rules" ref="ruleForm">
      <el-form-item label="监控维度：" prop="dimension" required>
        <el-radio-group v-model="formData.dimension">
          <el-radio label="RECEIPT">商户号收款</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="预警状态：" required prop="dimensionLogic">
        <el-radio-group v-model="formData.dimensionLogic">
          <el-radio label="LESS_THAN_QUOTA">可收款额度小于等于预警值</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="通知方式：" prop="notifyWay">
        <el-checkbox-group v-model="formData.notifyWay">
          <el-checkbox label="SMS">短信通知</el-checkbox>
          <el-checkbox label="QY_WX">企微通知</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <!-- v-default-select="[initContact,formData.contact]" -->
      <el-form-item label="接收人：" prop="contact" >
        <el-select  class="w300" multiple  v-model="formData.contact" placeholder="请选择">
          <el-option v-for="item in listData" :key="item.id" :value="item.mobile" :label="item.userName"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import payCenterApi from '@/api/paycenter';
export default {
  data(){
    return{
      initContact:[],
      listData:[],
      formData:{
        dimension:'RECEIPT',
        dimensionLogic:'LESS_THAN_QUOTA',
        notifyWay:[],
        contact:null
      },
      rules:{
        contact: [{ required: true, message: '请选择接收人', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.getWarningConfig()
  },
  methods:{
    async getWarningConfig () {
      const res = await payCenterApi.getWarningConfig()
      if(res.data) {
        this.formData = {...res.data}
        this.initContact = res.data.contact
      }
      this.getUserList()

    },
    async getUserList(){
      const {data} = await payCenterApi.getUserPage({
        currentPage:1,
        pageSize:9999
      })
      this.listData=[...data.records]
    },
    submitForm(){
      this.$refs['ruleForm'].validate(async (valid)=>{
        if(valid) {
          await payCenterApi.saveWarningConfig(this.formData);
          this.$message.success('保存成功');
          this.getWarningConfig()
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.w300 {
  width: 300px;
}
</style>